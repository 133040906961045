const initial_state = {
	labelContrato: 'Nº',
	NContrato: '000',
	aVista: 'R$ 0,0000',
	beneficiario: '---',
	debitoCorr: 'R$ 0,00',
	gerado: false,
	idCarteira: '---',
	isSMS: false,
	isSMSComBoleto: false,
	mensagem: '',
	mensagemacao: 'pague',
	mensagemacaogerado: 'pague',
	parcelas: [
		{
			valorCorrigido: 'R$ 0,0000',
			valorAVista: 'R$ 0,0000',
			numero: 1,
			valorParcela: 'R$ 0,0000',
			text: '1x R$ 0,0000'
		}
	],
	permiteReemissao: false,
	primVencOriginal: '---',
	qtdParcelasAtraso: '1',
	telRetorno: '---',
	textParcela: '',
	valor1Parcela: 'R$ 0,00',
	vencimento: '2019-01-01',
	vencimentos: [{ value: '---', text: '---' }],
	numParcela: 1,
	nuWhatsapp: false,
	detalhesAcordo: false,
	acordo: {
		PARCELAS: null,
		ID_ACORDO: undefined,
		DATA_ENVIO: null,
		TAXA_JUROS: null,
		TOTAL_MORA: null,
		TOTAL_CP: null,
		TOTAL_MULTA: null,
		VALOR_CET: null,
		CET_MENSAL: null,
		TAXA_MORA: null,
		TAXA_MULTA: null,
		CP_ANUAL: null,
		JUROS_ANUAL: null
	  }
};

const boleto = (state = initial_state, action) => {
		switch (action.type) {
			case 'SET_BOLETO':
				action.payload.numParcela = state.numParcela;
				state = action.payload;
				return state;
			case 'SET_NUMPARCELA':
				return { ...state, numParcela: action.payload };
			case 'SET_PARCELA':
				const { valorCorrigido, valorParcela, numero } = action.payload;
				return { ...state, debitoCorr: valorCorrigido, valor1Parcela: valorParcela, numParcela: numero };
			default:
				return state;
		}
	
};

export default boleto;
